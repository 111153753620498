import request from '@/utils/request'
const baseURL = process.env.VUE_APP_BASE_API

export function fetchCustomerAPPTList(params) {
    return request({
        url: baseURL + '/customerAPPT/list',
        method: 'GET',
        params: params
    })
}

export function fetchCustomerAPPTDetail(param) {
    return request({
        url: baseURL + '/customerAPPT/detail',
        method: 'GET',
        params: param
    })
}

export function customerAPPTSave(data) {
    return request({
        url: baseURL + '/customerAPPT/save',
        method: 'POST',
        data
    })
}