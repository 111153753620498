//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ContractDetail from "@/views/components/ContractDetail";
import { fetchProvince, fetchTransportOfficeWithProvince } from "@/api/master";
import { fetchCustomerAPPTDetail, customerAPPTSave } from "@/api/customerAPPT";
import { Message } from "element-ui";

export default {
  name: "CustomerAppointmentDetail",
  components: { ContractDetail },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.usersOpened = from.fullPath.includes("CheckDocument");
    });
  },
  data() {
    var validateDate = (rule, value, callback) => {
      if (value === null) {
        callback(new Error('กรุณาเลือกวันที่'));
      } else {
        callback();
      }
    };
    return {
      validate: false,
      items: [],
      province: [],
      transport: [],
      isLoading: false,
      contractParam: {
        contractId: this.$route.params.id,
      },
      formData: {
        contractId: "",
        statusId: 4,
        appointmentDate: "",
        provinceId: "",
        transportId: "",
        remark1: "",
      },
      transportOfficeParam: {
        provinceId: "",
        transportId: "",
      },
      formRules: {
        appointmentDate: [
          { required: true, validator: validateDate, trigger: 'change' }
        ],
        provinceId: [
          { required: true, message: 'กรุณาเลือกจังหวัดที่โอนรถ', trigger: 'change' }
        ],
        transportId: [
          { required: true, message: 'กรุณาเลือกขนส่งที่โอนรถ', trigger: 'change' }
        ],
      },
    };
  },
  computed: {
    contractDetail() {
      return this.items;
    },
  },

  async mounted() {
    this.$store.state.loading = true;
    try {
      await new Promise((resolve, reject) => {
        fetchProvince()
          .then((response) => {
            const check = response.header;
            const data = response.body;
            if (check.error === "N") {
              this.province = data;
              resolve();
            }
          })
          .catch((error) => {
            this.errorMsg = error;
            console.log(error);
            reject(error);
          });
      });
      await this.getCustomerAPPTDetail();
    } finally {
      this.$store.state.loading = false;
    }
  },

  methods: {
    getCustomerAPPTDetail() {
      fetchCustomerAPPTDetail(this.contractParam)
        .then((res) => {
          this.items = [];
          const check = res.header;
          const data = res.body;
          if (check.error === "N") {
            this.formData.contractId = data[0].contract_id;
            this.formData.appointmentDate = data[0].appointment_date;
            this.formData.provinceId = data[0].province_id;
            this.formData.transportId = data[0].transport_id;
            this.formData.remark1 = data[0].remark1;
            this.items = data[0];
            this.getTransportOfficeWithProvince(this.formData.provinceId);
            this.transportOfficeParam.provinceId = data[0].province_id;
            this.transportOfficeParam.transportId = data[0].transport_id;
          }
        })
        .catch((error) => {
          Message({
            message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
            type: "error",
            duration: 5 * 1000,
          });
          this.errorMsg = error;
          console.log("error :", error);
        });
    },
    async getTransportOfficeWithProvince(provinceId) {
      await fetchTransportOfficeWithProvince({ provinceId: provinceId })
        .then((response) => {
          const check = response.header;
          const data = response.body;
          if (check.error === "N") {
            this.transport = data;
          }
        })
        .catch((error) => {
          Message({
            message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
            type: "error",
            duration: 5 * 1000,
          });
          this.errorMsg = error;
          console.log("error :", error);
        });
      if (this.transportOfficeParam.provinceId !== this.formData.provinceId) {
        this.formData.transportId = null;
      } else {
        this.formData.transportId = this.transportOfficeParam.transportId;
      }
    },
    saveCustomerAPPT() {
      console.log("formData : ", this.formData);
      this.$refs['detailForm'].validate((valid) => {
        if (valid) {
          customerAPPTSave(this.formData)
              .then((response) => {
                const check = response.header;
                // const data = response.body
                if (check.error === "N") {
                  Message({
                    message: "บันทึกข้อมูลสำเร็จ",
                    type: "success",
                    duration: 2 * 1000,
                    onClose: () => {
                      this.goBack();
                    },
                  });
                }
              })
              .catch((error) => {
                Message({
                  message: "มีข้อผิดพลาด กรุณาติดต่อผู้ดูแลระบบ",
                  type: "error",
                  duration: 2 * 1000,
                });
                this.errorMsg = error;
                console.log(error);
              });
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    goBack() {
      this.usersOpened
        ? this.$router.go(-1)
        : this.$router.push({ path: "/step4/CustomerAppointment" });
    },
  },
};
